.field-card-container {
    min-height: 200px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 2px 20px transparent;
    transition: 0.3s ease-in-out;
    background-color: #2C5364;
    color: white !important;
}

.disabled-card {
    opacity: 0.8;
}

.field-card-container:hover,
.field-card-container:hover .field-card-container svg {
    /* background-color: var(--primaryLightColor); */
    /* color: white !important; */
    box-shadow: 0 2px 16px #2C5364;
}

.field-card-container h6 {
    font-size: 1.4rem;
    margin: 10px 0;

}

.field-card-container p {
    font-size: 0.9rem;

}

.add-field-form label {
    font-size: 0.9rem;
}

.count-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.count-container .count-numbers{
    background-color: #ffc107;
    min-width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    color: #1a313a;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
    padding: 0px 5px;
    height: 20px;

}