* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    font-size: 15px !important;
}

/* :root {
    --fixedbarWidth: 85px;
    --sidebarWidth: 225px;
    --primaryColor: #0fb9b1;
    --secondaryColor: #70e0d570;
} */

:root {
    --primaryTextColor: #1ED8D1;
    --fixedbarWidth: 70px;
    --sidebarWidth: 250px;
    --primaryDarkColor: #1ED8D1;
    --contrastPrimary: #fc7373;
    --primaryLightColor: #1ED8D1;
    --secondaryDarkColor: #2bcbba;
    --secondaryLightColor: #20c5b254;
    --primaryText: #2b3238;
    --secondaryText: #0fb9b1;
    --errorColor: #e63f63;
    --errorColorLight: #e65473;
    --bgDark: #1A2421;
    --bgLightDark: #232B2B;
}

.small-para {
    color: white;
    font-size: 0.8rem;

}



.primary-color {
    color: var(--primaryDarkColor);
}

.secondary-text {
    color: var(--primaryLightColor);
}

html {
    font-size: 1.1em;
}

body {
    font-family: "poppins", sans-serif;
    font-weight: 400;
    overflow-x: hidden;
    background-color: #0f2027;
}

.overflow-hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

a {
    text-decoration: none;
    font-size: 0.9rem;
    color: var(--primaryLightColor);
}

a:hover {
    color: var(--primaryDarkColor);
}

.btn-primary {
    background-color: var(--primaryLightColor);
    border: none;
    padding: 12px;
}


.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn.disabled,
.btn:disabled {
    background-color: #1B333B !important;
}

.error-msg {
    color: var(--errorColor);
    font-weight: 500;
    font-size: 0.9rem;
}

.no-hover {
    background-color: #43708011;
}

/* input,
textarea,
select {
    border: 1px solid var(--primaryLightColor) !important;
    font-size: 1rem;
    padding: 10px !important;
    resize: none;
}

input:focus,
input:active,
textarea:focus,
textarea:active,
select:focus,
select:active {
    outline: none !important;
    box-shadow: 0 0 4px 0 var(--primaryDarkColor) !important;
    border: 1px solid var(--primaryDarkColor);
} */

.input-group {
    position: relative;
}

.input-group input {
    padding-right: 55px !important;
    border-radius: 5px !important;

}

.input-group-append {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
}

.input-group-text {
    font-size: 1.1rem;
    background-color: transparent;
    border: none;
    color: var(--primaryDarkColor);
}

.custom-input.error_field {
    border: 1px solid var(--errorColor) !important;
    border-color: var(--errorColor) !important;
}

.custom-input.error_field:focus {
    box-shadow: 0 0 4px 0 var(--errorColor) !important;
}

/* Global Button Styling Start */

button.btn-primary,
button.btn-secondary {
    min-width: 120px;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 10px 25px;
    border-radius: 11px;
    transition: 0.2s ease-in-out;
}

button.btn-primary {
    background-color: #1B333B !important;
}

button.btn-primary:focus,
button.btn-primary:hover {
    box-shadow: 0px 0px 3px #336170 !important;
    background-color: #336170 !important;
}

button.btn-secondary {
    background-color: var(--errorColorLight);
}

button.btn-secondary:focus,
button.btn-secondary:hover {
    box-shadow: 0px 0px 3px var(--errorColor) !important;
    background-color: var(--errorColor) !important;
}

/* button.btn-primary {
    background-color: var(--primaryLightColor);
}

button.btn-primary:hover {
    background-color: var(--primaryDarkColor);
} */

/* button.btn-secondary {
    background: red; */
/* } */

/* Global Button Styling End */


/* Table Style Start  */

.action_drodown button.btn-primary {
    background-color: white !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 16px #00000014 !important;
    text-transform: capitalize;
    color: #284B5A;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 8px 0px;
}

.action_drodown button.btn-primary:hover,
.action_drodown button.btn-primary:focus {
    color: #284B5A;
}

.no-record {
    /* color: var(--primaryDarkColor); */
    font-weight: 500;
}

/* table .table-content tr:hover {
    background-color: #f5f5f5;
}

table {
    margin-top: 30px;
    border: 1px solid #dedede !important;
} */

/* table thead {
    background-color: var(--primaryLightColor);
    color: white;
} */

/* .table-hover>tbody>tr:hover>* {
    background-color: #13fdf515;
}

table .no-hover:hover {
    background-color: transparent !important;
}

table thead tr th {
    padding: 15px 10px !important;
    font-weight: 500;
} */

/* Table Style End */
.page-title {
    color: black;
    font-weight: 600;
}

.sub-title {
    font-size: 1.2rem;
}

.model-title {
    font-size: 1.3rem;
    font-weight: 600;
}


.modal-header {
    border-bottom: 0px;
}

.modal-header .close-icon {
    padding: 0px;
    height: 30px !important;
    width: 30px !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;

}

.modal-header .close-icon:hover,
.modal-header .close-icon:focus {
    color: var(--errorColor);
}

.upload-photo-label {
    display: block;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    flex-direction: column;
    width: 220px;
    height: 220px;
    position: relative;
    border: 5px solid var(--errorColor);
}


.upload-photo-label p {
    text-align: center;
    font-weight: 600;
    font-size: 1.4rem;
    color: white;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.upload-photo-label img {
    width: 100%;
    object-fit: cover;
}

.wrapper-upload {
    background-color: var(--errorColorLight);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.upload-photo-label span.icon {
    position: absolute;
    right: 0px;
    top: 5%;
}

.upload-photo-label span.icon svg {
    font-size: 4rem;
    color: var(--errorColorLight);
}



.img_preview_size_text {
    font-weight: 500;
    color: white;
    /* color: #2b323888; */
    opacity: 0.3;
    font-weight: bold;
    font-size: 1.1rem;

}

.content_card {
    /* box-shadow: 0 0 5px #dedede; */
    border-radius: 5px;
    padding: 25px;
    margin: 10px 0;
    overflow: hidden;
}


.nav-tabs .nav-link {
    color: black;
    text-transform: capitalize;
}

/* TAB PANEL  */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    /* min-width: 100px; */
    /* background-color: var(--contrastPrimary); */
    /* color: white; */
    /* border: none; */
    /* border-radius: 0; */
}

.content__custom__tab li:last-child {
    background-color: #f3f3f3;
    cursor: no-drop !important;
    border-radius: 0 11px 11px 0;

}

.nav-tabs {
    border: none !important;
}

.nav-tabs .nav-item button {
    border-radius: 0;
    border: none;
    background-color: #EFEFEF;
    color: #1B333B;
    min-width: 120px;
    padding: 15px;
    font-weight: 500;
}

.nav-tabs .nav-link.active {
    background-color: #1B333B;
    color: white;
}

.nav-tabs .nav-item:last-child button {
    border-radius: 0px 11px 11px 0px !important;
}

.nav-tabs .nav-item:first-child button {
    border-radius: 11px 0px 0px 11px !important;
}

/* a {
    text-decoration: none !important;
}

.upload-img-preview {
    width: 100%;
    background-color: aliceblue;
    font-size: 1.2rem;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.error-msg {
    color: rgb(228, 53, 53);
    margin: 20px 0;
    font-weight: 500;
    font-size: 0.75rem;
}

.profile_avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profile_avatar {
    width: 40px;
    height: 40px;
    background-color: #dedede;
    border-radius: 50%;
    margin-right: 10px;
}

.profile_avatar span {
    font-weight: bold !important;
    color: white;
}

tbody,
td,
tfoot,
tr {
    border-width: thin;
    border-color: #d1f9a1 !important;
}

.table thead th {
    border-bottom-width: thin !important;
    color: #495057;
    font-weight: 500;
    padding-top: 17px;
    padding-bottom: 17px;
    font-size: 15px;
    border-color: #e8e9e9 !important;
    background-color: #8fbd56;
    color: white;
}

.table td,
.table th {
    font-size: 13px;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.modal-title h4 {
    font-weight: normal;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #8fbd56 transparent;
}

*::-webkit-scrollbar {
    direction: ltr;
    width: 10px;
    background-color: #e6fcc9;
}

*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
    background-color: #8fbd56 !important;
    border-radius: 50px;
}

.dark-mode #left-sidebar,
.dark-mode .user_div {
    z-index: 100;
}

.card-title {
    font-weight: 500;
    font-size: 1.3rem;
    color: #0fb9b1 !important;
}

.loader-wrapper {
    justify-content: center;
    align-items: center;
}

.sub_text {
    color: #757575;
}



* {
    box-sizing: border-box !important;
}

body.font-poppin {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: normal;
}

.auth.option2 {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../public//assets/images/background/auth-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.page-header .right .nav-link {
    padding: 0px;
    margin-right: 10px;
    font-size: 20px;
}

.auth.option2 .card-body {
    padding: 80px 40px;
    border-radius: 15px;
} */

/* body {
    overflow-y: auto;
    overflow-x: hidden;
} */

/* .dark-mode .page .section-body {
    background-color: transparent;
}

.dark-mode .table.table_custom tr {
    background: #f3f3f3;
}

.card-wrap {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
    padding: 15px !important;
}

.dark-mode {
    background-color: #f7f8f9;
}

.dark-mode #page_top {
    border-bottom: 1px solid #eaedf1;
    background-color: #2b3238;
}

.dark-mode .page-title,
.dark-mode .form-control-plaintext {
    color: #495057 !important;
}

.btn-primary {
    color: #fff !important;
    background-color: #2bcbbbbe !important;
    border-color: #2bcbbbbe !important;
    transition: all 0.2s ease-in-out;
    padding: 8px 14px !important;
    border: none !important;
    outline: none !important;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.page-title {
    font-size: 1.2rem;
    font-weight: 500;
}

.dark-mode #left-sidebar .brand-name {
    color: #8fbd56;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-top: 29px;
}

.border-bottom {
    border-bottom: 1px solid #eaedf1 !important;
}

.card-header:first-child {
    background-color: #8fbd56;
    padding-top: 20px;
    padding-bottom: 20px;
}

.card-header:first-child h3 {
    font-weight: 500;
    color: white;
}

.card {
    border: none !important;
}

.dark-mode .card-header {
    border: none;
    box-shadow: 0 4px 16px 0 rgb(169 184 200 / 15%);
}

.dark-mode .card {
    background-color: white;
    border-radius: 10px !important;
}

.card-body {
    background-color: white;
}

.table-bordered,
.text-wrap table,
.table-bordered th,
.text-wrap table th,
.table-bordered td,
.text-wrap table td {
    border: 1px solid #eaedf1 !important;
}

.table> :not(:first-child) {
    border: none !important;
} */

/* .table.table-hover tbody tr:hover {
    cursor: pointer;
} */
/* 
tbody,
td,
tfoot,
th,
thead,
tr {
    vertical-align: middle;
}

table .btn-icon {
    font-size: 1.2rem;
}

table .btn-icon .fa-trash {
    color: red;
}

table .btn-icon .fa-pencil {
    color: #8fbd56;
}

tr.not-found td:hover {
    --bs-table-accent-bg: transparent !important;
}

.highlightOption {
    margin: 0px;
}

.search-wrapper {
    border-color: #343a40 !important;
}

.chip,
.highlightOption,
.multiSelectContainer li:hover {
    background-color: #8fbd56 !important;
    border-radius: 0 !important;
    border: none !important;
}

.highlight {
    border-radius: 0 !important;
    border: none !important;
}

.hleft {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.custom-card .filters input,
.custom-card .filters select {
    width: 100%;
}

.custom-card .filters {
    padding: 0 15px;
}

.page-link:hover {
    color: white !important;
}

.page-item.active .page-link {
    background-color: #8fbd56 !important;
    border-color: #8fbd56 !important;
}

.page-item:not(:first-child) .page-link {
    color: #8fbd56;
}

.page-item:first-child .page-link {
    color: #8fbd56;
}

.text-muted a {
    color: #2bcbbbbe;
    font-weight: 500;
}

.text-muted a:hover {
    color: #2bcbbb !important;
} */

/* .text-muted a,
.text-muted a:hover {
    color: #8fbd56;
    font-weight: bold;
} */
/* /* 
.btn-small {
    padding: 5px 12px !important;
    font-size: 13px !important;
}

.btn-group a.dropdown-item {
    font-size: 13px !important;
}

table td.title-td a,
a:hover {
    color: #8fbd56 !important;
}

a.delete {
    color: red !important;
} */

/* .tag-success {
    color: #545454;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    background-color: rgb(201, 229, 166) !important;
}

.pills-panel-container button {
    min-width: 100px;
}

.paging_simple_numbers li {
    cursor: pointer;
}

.table {
    box-shadow: "0 0 10px #dedede" !important;
}

.page-link:hover {
    background-color: #bde28f !important;
}

.pills-panel-container .tab-content {
    margin: 25px !important;
    padding: 20px !important;
}

.tab-content {

    border-radius: 10px !important;
    border-top-left-radius: 0px !important;
}

.modal-header .btn-close {
    font-size: 1rem;
}

.tab-panel {
    font-weight: bold;
    color: #8fbd56;
}

.field-card-container {
    min-height: 150px;
    text-align: center;
}

.field-card-container svg {
    font-size: 3rem;
    color: #8fbd56;
    height: 40px;
    transition: 0.2s ease-in-out;
    width: 40px;
}

.field-card-container h6 {
    font-size: 1rem;
    margin-top: 1rem;
}

.field-card-container p {
    font-size: 0.8rem;
    font-weight: normal;
}

.modal-content .card {
    box-shadow: 0 1px 5px rgb(52 58 64 / 10%);
    cursor: pointer;
}

.field-card-container:hover {
    background-color: #8fbd56;
    transition: 0.2s ease-in-out;
    color: white;
}

.field-card-container:hover svg {
    color: white;
}

.back-icon {
    font-size: 1.5rem;
    margin-right: 10px;
    cursor: pointer;
}

input,
textarea,
select {
    border-color: #2bcbba !important;
    padding: 0.7rem !important;
}

input:focus,
textarea:focus,
select:focus {
    box-shadow: 0px 0px 4px 0px rgba(43, 203, 187, 0.397) !important;
    outline: none !important;
}


.form-group .input-group-append span svg {
    margin: 0 auto;
}

.form-group .input-group-append span {
    background-color: #2bcbbbbe;
    border: none;
    color: white;
    height: 100%;
    justify-content: center;
    min-width: 50px;
    text-align: center;
    cursor: pointer;
}

.icon.settingbar.fixed-bar {
    background-color: #8fbd56;
    border-radius: 50%;
}

#header_top {
    padding: 0px !important;
    padding-bottom: 10px;
} */

/* .icon-wrapper {
    background-color: var(--primaryColor) !important;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 50% 50%;

}

.offcanvas-active #header_top {
    border: none !important;
}

.icon-wrapper .menu_toggle,
.icon-wrapper .menu_toggle:hover {
    color: white !important;
}

#left-sidebar {
    padding: 0px !important;
    padding-left: 70px !important;
    height: 100vh;
    overflow: hidden;
}

.page-header {
    padding: 15px 0px !important;
}

.profile_img img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.modal-header {
    background-color: #8fbd56;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.modal-header .modal-title {
    color: white;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.modal-content {
    border: none !important;
    border-radius: 5px !important;
}

.modal-header .btn-close {
    background-color: white !important;
}

.dashboard-text {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 75px);
}

.dashboard-text h1 {
    margin-top: 20px;
    font-size: 3rem;
    font-weight: bold;
    color: #8fbd56;
    text-transform: uppercase;
}

.dashboard-text p {
    font-size: 24px;
}

.dashboard-text i {
    color: #8fbd56;
    font-size: 5rem;
}

.multiSelectContainer input,
.multiSelectContainer input:focus {
    background-color: transparent !important;
}

.page-item:not(:first-child).active .page-link {
    color: white !important;
}

.btn {
    min-width: 100px;
}

.mleft-2 {
    margin-left: 10px;
}

.swal2-loader {
    border-color: #8fbd56 transparent #8fbd56 transparent !important;
    width: 4rem !important;
    height: 4rem !important;
}

.disabled-card {
    opacity: 0.5;
    cursor: no-drop !important;
}

.rotate-refresh {
    animation: spin 1s infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.selected-badge {
    background-color: #8fbd56;
    color: white;
    padding: 4px 10px;
    margin: 3px;
    display: inline-block;
    border-radius: 50px;
    margin-left: 0;
    font-size: 0.9rem;
}

.pills-panel-container {
    border: none !important;
}


.nav-tabs .nav-link {
    border: none !important;
    border-top: 3px solid transparent !important;
    border-radius: 0px !important;
    transition: 0.3s ease-in-out;
    background-color: #f8f8f8 !important;
}

.nav-tabs .nav-link.active {
    background-color: #f3f3f3 !important;
    border-top: 3px solid #8fbd56 !important;
}

.nav-tabs .nav-item {
    padding: 0px !important;
} */

/* .form-group .input-group-append span,
label.form-label {
    user-select: none !important;
}


.tab-panel .tab-content {
    background-color: #f9f9f9 !important;
    padding: 20px;
    min-height: 400px;
}

.pills-panel-container>li:first-child {
    margin-left: 12px;
}

.disclaimer h5 {
    color: #8fbd56;
}

.form-control.field-key {
    color: white !important;
    background-color: #6a6a6a !important;
    border-color: #6a6a6a !important;
}

.form-control.field-key:focus {
    box-shadow: none !important;

}

.disclaimer ul li {
    margin: 15px 0;
}

input.dynamic {
    background-color: white !important;
}

.input-group-append .input-group-text {
    border-radius: 0px 5px 5px 0px;
}

.auth .auth_left {
    min-width: 450px;
} */


/* .table.table-hover tbody tr.no-hover:hover {
    cursor: auto !important;
    background-color: white !important;
} */
/* 
.table.table-hover tbody tr:hover {
    background-color: #f5f5f5 !important;
    cursor: pointer !important;
}

.table.table-hover tbody tr.no-hover:hover {
    background-color: white !important;
    cursor: auto !important;
}

.table> :not(caption)>*>* {
    box-shadow: none !important;
}

.modal-header .btn-close {
    padding: 15px !important;
}

.nav-tabs .nav-link {
    padding: 15px;
} */

/* 
.dark-mode .form-control, .dark-mode .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dark-mode .dataTables_filter, .dark-mode .input-group-append>.btn, .dark-mode .dataTables_wrapper .dataTables_paginate .input-group-append>.paginate_button, .dataTables_wrapper .dataTables_paginate .dark-mode .input-group-append>.paginate_button, .dark-mode .custom-select{

} */
/* 
input,
textarea,
select,
span {
    user-select: none;
}

textarea {
    resize: none !important;
} */

/* .btn:first-child:hover, :not(.btn-check) + .btn:hover , .btn:first-child:focus, :not(.btn-check) + .btn:focus {
    background-color: rgb(64, 68, 71)!important;
    border-color: rgb(64, 68, 71)!important;
        transition: 0.2s ease-in-out!important;

} */
/* 
button.btn-primary:hover,
button.icon:hover,
button.btn-primary:focus,
button.icon:focus {
    transition: 0.2s ease-in-out !important;
    background-color: #2bcbbb !important;
} */


/* Login PAGE STYLING  */




.logo-heading {
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin: 0;
    color: white;
    background-color: #361e12;
}


.section-body {
    display: inline-block;
    position: relative;
    width: calc(100% - 110px - 50px);
    margin-left: calc(110px);
    margin-top: 100px;
    transition: width 0.8s ease-in-out;
    transition-property: width, margin-left;

}

.animated-open-menu~.section-body {
    width: calc(100% - var(--fixedbarWidth) - var(--sidebarWidth) - 50px);
    margin-left: calc(25px + var(--fixedbarWidth) + var(--sidebarWidth));
    transition: width 0.8s ease-in-out;
    transition-property: width, margin-left;
}

.searchWrapper .chip {
    background-color: var(--primaryDarkColor);
}

label {
    word-break: break-all;
}

.disabled-link {
    opacity: 0.5;
    cursor: no-drop;
}

.tag {
    border-radius: 6px;
    min-width: 90px;
    padding: 8px;
    display: inline-block;
    font-size: 0.9rem;

    text-transform: capitalize;
}

.tag-success {
    color: #00ACA6 !important;
    background-color: #43fff927 !important;
}

.tag-error {
    /* background-color: var(--errorColorLight); */
    background-color: #ed1c2321;
    color: #ED1C24;
}

.alert-primary {
    transition: 0.3s ease-in-out;
    padding: 10px;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: var(--primaryDarkColor);
    border: none;
    color: white;
    text-align: center;
}

.alert-secondary {
    transition: 0.3s ease-in-out;
    padding: 10px;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: var(--contrastPrimary);
    border: none;
    color: white;
    text-align: center;
}

/* .table thead tr th {
    width: 80px !important;
} */

/* tbody,
td,
tfoot,
th,
thead,
tr {
    max-width: 180px;
}

.table>:not(caption)>*>* {
    vertical-align: middle;
    font-size: 0.9rem;
    padding: 5px 10px;
}

.action_drodown button {
    font-size: 0.9rem;
    padding: 5px;
}

.action_drodown .dropdown-menu {
    z-index: 1;
}

table .dropdown-menu-light a {
    font-size: 0.8rem;
} */

.delete {
    color: var(--errorColorLight);
}


.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active {
    background-color: #43708011;
    color: var(--primaryLightColor);

}

.delete:hover,
.delete:focus {
    background-color: var(--errorColorLight) !important;
    color: white;
}

.active>.page-link,
.page-link.active {
    background-color: #2C5364;
    border: #2C5364;
    cursor: pointer;
}

.page-link,
.page-link:hover,
.page-link:focus {
    color: black;
}

.modal-content p,
.modal-content h4,
.modal-content input,
.modal-content textarea,
.modal-content button,
.modal-content svg,
.modal-content label,
.modal-content div {
    /* user-select: none; */
}

.copy-icon {
    position: relative;
    display: block;
}

/* .custom_tooltip {
    position: absolute;
    background-color: orange;
    top: 20%;
    left: 30px;
    padding: 5px;
    font-size: 0.8rem;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    opacity: 0;
    display: none;
    user-select: none;
    transition: 0.8s ease-in-out;
}

.copied .custom_tooltip {
    opacity: 1;
    display: block;
    transition: 0.8s ease-in-out;
} */

.custom_tooltip {
    position: absolute;
    top: -25px;
    /* background-color: orange; */
    font-weight: 600;
    font-size: 0.8rem;
    background-color: #0F2027;
    color: white;
    padding: 4px 5px;
    border-radius: 5px;
    display: none;
}

.custom_tooltip::after {
    content: "\2039";
    font-size: 2.5rem;
    top: -3px;
    /* background-color: orange; */
    color: #0F2027;
    position: absolute;
    /* color: #0F2027; */
    left: 40%;
    transform: rotate(270deg);
    /* width: 20px;
    height: 20px; */
}

/* Pagination Styling  */
.page-item.active a {
    color: white;
}

.page-link {
    cursor: pointer;
}

/* Table Styling */
.custom__table {
    width: 100%;
}

.custom__table thead {
    background: transparent linear-gradient(91deg, #0F2027 0%, #203A43 50%, #2C5364 100%);
    color: white;
}

.custom__table thead th {
    padding: 20px 10px;
    text-transform: capitalize;
    font-weight: 500 !important;
}

table th:first-child {
    border-radius: 7px 0 0 7px;
}

table th:last-child {
    border-radius: 0 7px 7px 0;
}

.custom__table__body tr td {
    padding: 8px;
    color: #707070;
    max-width: 240px;
}

.limit-data {
    overflow: hidden;
    white-space: nowrap;
}

.custom__table__body tr {
    transition: 0.1s ease-in-out;
    cursor: pointer;
}

.custom__table__body tr:nth-child(even) {
    background-color: #FAFAFA;
}

.custom__table__body tr:hover {
    background-color: #43708011;
}

.dropdown-menu.show {
    border: none;
    box-shadow: 0px 1px 16px #00000014;

}

.custom__table__wrapper {
    /* overflow-x: auto; */
    width: 100%;

}

.sidebar__header__wrapper {
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.custom-label-1 {
    color: #0F2027;
    font-weight: 600;
}

.custom-input {
    border: 1px solid transparent !important;
    background-color: #F3F3F3;
    color: #333;
    border-radius: 5px;
    padding: 12px 15px;
    outline: none;
    resize: none;
    font-weight: 400;
    transition: 0.2s ease-in-out;
}

.custom-input:focus,
.custom-input:active {
    background-color: #F3F3F3;
    color: #333;
    border: 1px solid #1B333B;
    box-shadow: 0 0px 2px #1B333B;

}

.custom-btn-2 {
    background-color: white;
    color: #1B333B;
    border-radius: 5px;
    padding: 11px 25px;
    border: none;
    box-shadow: 0px 1px 16px #00000014;
    min-width: 120px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
}

.custom-btn-2:hover,
.custom-btn-2:active,
.custom-btn-2:focus {
    background-color: #4a9bb6;
    color: white;
    box-shadow: 0px 1px 16px #4a9bb68a;
}

.modal-footer {
    border-top: none;
}

.re_write {
    color: var(--errorColor);
    display: block;
    font-weight: 600;
    word-break: break-all;
}

/* code {
    color: var(--errorColorLight);
    background-color: #dedede;
    border-radius: 5px;
    text-align: center;
    display: block;
    word-break: break-word;
    padding: 2px 10px 2px 10px;
    margin-left: 5px;
    font-size: 1rem;
} */
.capitalize {
    text-transform: capitalize;
}

.link__expire__content {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    color: white;
    padding: 20px;
    text-align: center;
}

/* Content Wrapper  */
.content__wrapper__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content__wrapper__header .title {
    font-weight: 600;
    margin-bottom: 0px;
}

.content__card__wrapper {
    margin-top: 50px;
}

.custom__card {
    border-radius: 11px;
    box-shadow: 0px 1px 16px #00000014;
    padding: 20px;
    min-height: 300px;
}

.card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.card__header svg {
    font-size: 1.3rem;
    color: #B0B4C0;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.card__header svg:hover:first-child {
    color: var(--primaryDarkColor);
}

.card__header svg:hover:last-child {
    color: var(--errorColorLight);
}

.card__img {
    height: 60px;
    object-fit: cover;
    width: 60px;
    border-radius: 11px;
    box-shadow: 0px 1px 16px #00000014;
}

.card__letter {
    min-width: 60px;
    min-height: 60px;
    border-radius: 11px;
    display: inline-block;
    background-color: orange;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
}

.inner__card__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

/* .modal-backdrop {
    --bs-backdrop-opacity: 0.2;
} */
.card__content .total_workspace {
    color: #707070;
    font-weight: 400;
    font-size: 1rem;
    margin: 10px 0;
}

.card__content .card__discription {
    color: #707070;
    font-weight: 400;
    font-size: 1rem;
    margin: 20px 0;
    word-break: break-all;
    /* white-space: nowrap; */
}

.card__content h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #0F2027;
    font-size: 1.3rem;
    font-weight: 600;

}


span.no__org__found {
    color: #e6e5e5;
    font-weight: 600;
    font-size: 1.1rem;
    transition: 0.3s ease-in-out;
    text-align: center;
    display: block;
    margin-top: 40px;

}

.no__org__found__main {
    text-align: center;
}

.content_model_detail {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    color: #707070;
}

.center_line {
    width: 2px;
    height: 80px;
    background-color: #dedede;
}

.workspace_label {
    /* background-color: #C0F3F2; */
    text-transform: uppercase;
    color: white;
    border-radius: 5px;
    padding: 2px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
}

.single__workspace span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.content_model_detail .head {
    font-size: 0.8rem;
    font-weight: 600;
    color: #1B333B;
    margin-bottom: 0.5rem;
}

.lang__icon {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-transform: uppercase;
}

.lang__icon__wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    min-width: 90px;
    display: flex;
    justify-content: center;
    align-items: center
}

.lang_icon_1 {
    color: #029E92;
    background-color: #35EBDD;
}

.lang_icon_2 {
    margin-left: -20px;
    background-color: #73E9FB;
    color: #1A899A;
}

.lang_icon_3 {
    margin-left: -20px;
    color: #345DB4;
    background-color: #91B4FF;
}

.lang_icon_4 {
    margin-left: -20px;
    color: #8A64D6;
    background-color: white;
    box-shadow: 0px 1px 16px #00000014;
}

.cts__breadcrumbs span,
.cts__breadcrumbs a {

    font-size: 1rem;
    color: #808285;
}

.cts__breadcrumbs a {
    font-weight: 600;
}

.cts__breadcrumbs span {
    margin: 0 5px;
    font-size: 1.4rem;
    vertical-align: middle;

}

.cts__breadcrumbs a.active {
    color: #303F4B;

}

.loader__background {
    background-color: #fff;
    z-index: 99999;
    width: 100%;
    position: absolute;
}