/* .sidebar__wrapper {
    max-width: 300px;
    height: 100vh;
    background-color: #0F2027;
} */

/* .sidebar__header {
    height: 60px;
    color: white;
    display: flex;
    align-items: center;
}


.sidebar__header img {
    max-width: 35px;
    margin: 0 10px;
}

.sidebar__header .hamburger-react {
    margin-left: 13px;
}

.sidebar__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 10px 20px;
}

.sidebar__actions svg {
    cursor: pointer;
}

.sidebar__actions svg:hover {
    color: var(--primaryTextColor);
}

.org__img {
    max-width: 45px;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
}

.org__img__name {
    min-width: 45px;
    color: white;
    background-color: orange;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
} */


/* Animation Start  */
/* .hide--width {
    transition: 0.2s ease-in-out;
    max-width: 0px !important;
}

.hide-font {
    transition: 0.2s ease-in-out;
    font-size: 0rem !important;
} */


/* Header Styling  */
/* .header__wrapper {
    background-color: orange;
    position: fixed;
    top: 0;
    min-height: 60px;
    transition: 0.2s ease-in-out;
}

.collapse-sidebar~.header__wrapper {
    width: calc(100% - 80px);
    margin-left: 80px;
}

.sidebar~.header__wrapper {
    width: calc(100% - 250px);
    margin-left: 250px;
}


.content__wrapper {
    min-height: calc(100% - 70px);
    background-color: red;
    position: absolute;
    top: 70px;
    transition: 0.3s ease-in-out;
    padding: 20px;
}

.sidebar~.content__wrapper {
    width: calc(100% - 250px);
    margin-left: 250px;
}

.collapse-sidebar~.content__wrapper {
    width: calc(100% - 80px);
    margin-left: 80px;
} */

/* ADDED STYLE IN SLIDER COMPONENT  */
/* .collapse-sidebar .ps-submenu-expand-icon {
    display: none;
}

a.ps-menu-button {
    padding: 30px 20px;
}

.ps-submenu-content a.ps-menu-button {
    padding: 0px;
    padding-left: 40px;
    height: 35px;
} */

/* 
a.ps-menu-button:hover {
    background-color: #1C3C4A;
    color: white;
} */



/* New Styling Are From Here  */

.sidebar__wrapper {
    position: fixed;
    background-color: #0F2027;
    height: 100vh;
    overflow-y: auto;
    color: white;
    transition: 0.4s ease-in-out;
    width: 300px;
}

.sidebar__wrapper.collapse__sidebar {
    width: 70px;
}

.sidebar__header {
    display: flex;
    align-items: center;
    min-height: 70px;
    padding: 0 20px;
    transition: 0.3s ease-in-out;
    padding-right: 0px;
}

.sidebar__header--logo {
    max-width: 90px;
    margin: 14px 10px 0;
    transition: 0.3s ease-in-out
}

.sidebar__sub__header {
    padding: 10px 20px;
    transition: 0.3s ease-in-out;
}

.single__org__wrapper {
    /* max-height: 60px; */
    /* overflow: hidden; */
    color: white;
}



.single__org__wrapper:hover {
    color: white;
}

.org__Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.single__org__wrapper:hover .org__Wrapper,
.single__org__wrapper.active .org__Wrapper {
    background-color: #1C3C4A;
}

.org__logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.org__logo__text {
    background-color: orange;
    text-transform: uppercase;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
}

.org__name {
    margin-left: 10px;
    display: inline-block;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    align-self: center;
}

.org__arrow {
    float: right;
    transform: rotate(0deg);
    font-size: 1.5rem;
    transition: 0.3s ease-in-out;
}

.single__org__wrapper.active .org__arrow {
    transform: rotate(180deg);
}

/* Workspace Section  */
.single__workspace {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 40px;
    align-items: center;
    overflow: hidden;
    height: 0px;
    transition: 0.2s ease-in-out;
    color: white;
    background-color: #142E39;
}

.single__workspace:hover,
.workspace__link.active .single__workspace {
    background-color: #1C3C4A;
}

.workspace__link.active .single__workspace::before {
    content: "";
    width: 7px;
    height: 7px;
    background-color: var(--primaryTextColor);
    position: absolute;
    left: 25px;
    border-radius: 50%;
    transition: 0.2s all;
}

.single__workspace .env__label {
    /* background-color: var(--primaryTextColor);
    color: black; */
    min-width: 50px;
    text-align: center;
    border-radius: 20px;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 2px 0px;
    transition: 0.2s ease-in-out;
    text-transform: uppercase;
}

.badge-1 {
    /* background-color: #4e7b36; */
    background-color: #4accae;
}

.badge-2 {
    /* background-color: #429EA8; */
     background-color: #2ab8fd;
}

.badge-3 {
    /* background-color: #6289FF; */
    background-color: #8481f7;
}

.badge-4 {
    /* background-color: #d3434a; */
    background-color: #f06a7d;
}


.single__org__wrapper.active .single__workspace {
    height: auto;
    padding: 10px 20px 10px 40px;
}

.sidebar__header--text {
    transition: 0.3s ease-in-out;
    color: white !important;
    font-weight: 500;
}

.collapse__sidebar .sidebar__header--logo,
.collapse__sidebar .sidebar__header--text,
.collapse__sidebar .sidebar__sub__header,
.collapse__sidebar .org__name,
.collapse__sidebar .single__workspace {
    font-size: 0rem;
    max-width: 0px;
}

.collapse__sidebar .no__org__found {
    font-size: 0rem;
}

.collapse__sidebar .single__workspace .env__label,
.collapse__sidebar .single__org__wrapper.active .single__workspace,
.collapse__sidebar .workspace__link.active .single__workspace::before,
.collapse__sidebar .single__org__wrapper.active .single__workspace {
    font-size: 0rem;
    max-width: 0px;
    padding: 0;
}

.collapse__sidebar .org__Wrapper {
    padding: 10px 10px;
}

/* HEADER LAYOUT  */

.header__wrapper {
    width: calc(100% - 300px);
    margin-left: 300px;
    background: linear-gradient(105deg, #0F2027 0%, #203A43 50%, #2C5364 100%) 0% 0% no-repeat;
    min-height: 70px;
    position: fixed;
    top: 0;
    z-index: 100;
    transition: 0.3s ease-in-out;
}

.collapse__sidebar~.header__wrapper {
    margin-left: 70px;
    width: calc(100% - 70px);
}

.content__wrapper {
    position: absolute;
    margin-left: 300px;
    top: 70px;
    width: calc(100% - 300px);
    transition: 0.4s ease-in-out;
    background: #fff;
    min-height: calc( 100vh - 70px );
}

.collapse__sidebar~.content__wrapper {
    margin-left: 70px;
    width: calc(100% - 70px);
}

.header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding-right: 30px;
}

/* Hamburger Bar Animations  */

.hamburger__bar {
    margin-top: 10px;
    width: 25px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}


.hamburger__bar span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.hamburger__bar span:nth-child(1) {
    top: 0px;
}

.hamburger__bar span:nth-child(2),
.hamburger__bar span:nth-child(3) {
    top: 8px;
}

.hamburger__bar span:nth-child(4) {
    top: 16px;
}

.hamburger__bar.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.hamburger__bar.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger__bar.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hamburger__bar.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.quick__navigation a {
    padding: 10px;
}

.quick__navigation .icon img {
    max-width: 25px;
}

.quick__navigation .icon {
    padding: 0 15px;
    transition: 0.3s ease-in-out;

}

.quick__navigation .icon-text {
    margin-left: 8px;
    color: white;
    font-size: 0rem;
    transition: 0.3s ease-in-out;
    vertical-align: middle;
}

.quick__navigation a.active .icon-text {
    font-size: 1rem;
}

.quick__navigation a.active {
    background-color: var(--primaryTextColor);
    border-radius: 50px;
}

.sidebar__org__wrapper {
    overflow-x: hidden;
}

.upload__img__section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;

}

.upload__img__section img {
    max-width: 100%;
    border-radius: 25px !important;
    padding: 10px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

.upload__btn {
    display: block;
    width: 100%;
    background-color: white;
    box-shadow: 0px 1px 16px #00000014;
    border: none;
    padding: 10px 0;
    margin: 10px 0px;
    border-radius: 11px;
    cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 10px 0px;
    cursor: pointer;
}