.no__org__found__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 300px);
    opacity: 0.7;
}

.no__org__found__main h1 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;

}

.no__org__found__main h4 {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    font-family: monospace;

}

.no__org__found__main h2 {
    font-size: 46px;
    color: red;
    margin-bottom: 15px;

}