.content_field_wrapper {
    /* padding: 15px;
    box-shadow: 0 0 5px 1px #f5f5f5;
    border-radius: 10px; */
    margin-top: 25px;
    /* transition: 0.2s ease-in-out; */
}

.content_field_wrapper:hover,
.content_field_wrapper:focus {
    /* box-shadow: 0 0 10px 2px #e8e8e8;
    transition: 0.2s ease-in-out; */
}

.content_field_wrapper input:disabled {
    background-color: var(--contrastPrimary);
    border: none !important;
    color: white;
    cursor: no-drop !important;
}

.content_field_wrapper .lock_input {
    position: relative;
}

.content_field_wrapper .lock_input svg {
    position: absolute;
    top: 55%;
    right: 10px;
    font-size: 1.5rem;
    color: white;
    /* color: var(--primaryDarkColor); */
}

.disclaimer h5 {
    color: #1B333B;
}

.disclaimer ul li {
    padding: 10px 0;
    color: #828282;
    font-weight: 500;
    font-size: 1.1rem;
}
.disclaimer ul li span{
    font-weight: 601;
}

.create__content__wrapper {

    background-color: white;
    box-shadow: 0px 1px 16px #00000014;
    padding: 10px 25px;
    border-radius: 11px;
    min-height: 300px;
}

.nav-tabs {
    border-bottom: none;
}

.no-result {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    margin-top: -1.5rem !important;
}

.no-result h1 {
    font-size: 1.5rem;
}