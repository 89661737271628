.auth__input__wrapper {
    width: 100%;
    position: relative;
}

.auth__input__wrapper input {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid white;
    color: white;
    z-index: 2;
    position: absolute;
}

.auth__input__wrapper label {
    color: white;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    top: 0;
    cursor: text;
    transition: 0.3s all;

}

.auth__input__wrapper input:focus~label,
.auth__input__wrapper input:not(:placeholder-shown)~label {
    top: -25px;
    color: var(--primaryTextColor);
}

.auth__input__wrapper input:focus {
    border-bottom: 2px solid var(--primaryTextColor);
}

.auth__input__wrapper svg {
    position: absolute;
    right: 0;
    z-index: 3;
    font-size: 1.4rem;
    cursor: pointer;
    user-select: none;
}

.auth__input__wrapper input[data-autocompleted] {
    background-color: transparent !important;
}

.auth__input__wrapper input:-webkit-autofill,
.auth__input__wrapper input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.auth__error {
    color: var(--primaryErrorTextColor);
    position: absolute;
    top: 30px;
    width: 100%;
    font-size: 0.8rem;
    user-select: none;
}