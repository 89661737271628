.content_api_key .icon {
    font-size: 1rem;

}

.content_api_key .icon {
    /* padding: 5px; */
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
    color: var(--primaryDarkColor);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    display: inline-block;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    float: right;

}
.api_key_container .icons-container{
    float: right;
}

.api_key {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    user-select: none;
}

.content_api_key .api_key {
    margin-left: 5px;
}

.content_api_key .icon:hover {
    background-color: var(--primaryDarkColor);
    color: white;
    transition: 0.3s ease-in-out;
}

.content_api_key .eye-icon::after {
    content: '\f070';
    font-family: "FontAwesome";
}

.content_api_key .eye-icon.show-password::after {
    content: '\f06e';
    font-family: "FontAwesome";
}

.content_api_key .eye-icon.show-password+.api_key {
    filter: blur(0px);
    -webkit-filter: blur(0px);
    user-select: auto;
}