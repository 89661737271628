.auth__container {
    width: 100vw;
    min-height: 100vh;
    background: url("../../../public/assets/images/background/auth_pattern.png") no-repeat fixed bottom left, linear-gradient(113deg, #0F2027 0%, #203A43 50%, #2C5364 100%) 0% 0% no-repeat;
    ;
}

.auth__intro {
    display: flex;
    justify-content: center;
    height: 100vh;
    color: white;
    flex-direction: column;
    font-size: normal;
    padding: 2rem 2rem 2rem 8rem;
}

.auth__intro--logo {
    display: flex;
    align-items: center;
}

.auth__intro--logo img {
    max-width: 250px;
    margin-right: 20px;
}

.auth__intro--logo h3 {
    font-size: 3rem;
    margin-bottom: 0px;
}

.auth__intro--content h1 {
    font-size: 4rem;
    line-height: 5rem;
    margin: 1.5rem 0;
}

.auth__intro--content h1 span {
    color: var(--primaryTextColor);
}

/* Form Content  */

.auth__form__container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth__form--content {
    background-color: #142E39;
    min-width: 440px;
    min-height: 540px;
    border-radius: 8px;
    padding: 3rem;
    overflow: auto;
}


.auth__form--heading {
    color: white;
    text-align: center;
    padding: 3rem;
}

.auth_remember {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    align-items: center;
    color: white;
    font-size: 0.85rem;
}

.auth__container #remember_me {
    vertical-align: middle;
    color: white;
    background-color: red;
}

.auth_remember label {
    margin-left: 7px;
}

.auth_remember a {
    font-size: 0.85rem;
    color: white;
    transition: 0.3s all;
}

.auth_remember input[type=checkbox] {
    transform: scale(1.1);
    accent-color: var(--primaryTextColor);
    cursor: pointer;
}

@media(max-width:991px) {
    .auth__intro {
        padding: 1rem;
        height: auto;
        text-align: center;
    }

    .auth__form__container {
        min-height: auto;
        padding: 20px;
    }

    .auth__intro--logo {
        margin: 20px;
    }

    .auth__intro--logo img {
        max-width: 50px;
    }

    .auth__intro--logo h3 {
        font-size: 1.5rem;
    }

    .auth__intro--content h1 {
        font-size: 3rem;
        line-height: 3.5rem;
    }
}

.register-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.register-link span {
    color: white;
}

.auth__error {
    color: var(--errorColorLight);
}

@media(max-width:500px) {
    .auth__form--content {
        min-width: 95%;

    }

    .register-link {
        flex-direction: column;
    }

    .auth__form__container {
        padding: 20px 0;
    }

    .auth__intro--logo img {
        max-width: 30px;
        margin-right: 5px;
    }

    .auth__intro--logo h3 {
        font-size: 1rem;
    }

    .auth__intro--content h1 {
        font-size: 2rem;
        line-height: 2.2rem;
    }

    .auth__form--content {
        padding: 1.5rem;
    }
}

.auth__form--content .btn-primary {
    background-color: white !important;
    color: #44C4B9;
}

.auth__form--content .btn-primary:disabled {
    background-color: #336170ad !important;
    color: white !important;
}